import React from "react"
import { AspectRatio, Image, Text, Box } from "@chakra-ui/react"

import { useImage } from "@app/hooks/useImage"

import type { NormalisedProduct } from "@root/types/custom-types/Product/Product"

type Props = {
  item: NormalisedProduct
}

const ProductCarouselItem: React.FC<Props> = ({ item }) => {
  const { getGatsbyImage } = useImage()

  const label = item.vendor
  const customImage = getGatsbyImage(item.images)
  const title = item.title.toLowerCase()

  if (!customImage) return null

  return (
    <Box w="full">
      <AspectRatio ratio={1 / 1} d="block" mb="5">
        <Image {...customImage[0]} />
      </AspectRatio>

      <Text size="largeParagraph" textTransform="capitalize">
        {title}
        <br />
        {label}
      </Text>
    </Box>
  )
}

export default React.memo(ProductCarouselItem)
