import React from "react"
import { Box, Flex, IconButton } from "@chakra-ui/react"
import SwiperCore, { Navigation } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css"

import { Icon } from "@app/components/Icon"
import ProductCarouselItem from "@app/components/Sections/ProductsCarousel/ProductCarouselItem"

import type { NormalisedProduct } from "@root/types/custom-types/Product/Product"

SwiperCore.use([Navigation])

type Props = {
  id: string
  items: NormalisedProduct[] | undefined
}

const FeaturedProductsCarousel: React.FC<Props> = ({ id, items }) => {
  if (!items?.length) return null

  const navButtonStyles = {
    d: "inline-flex",
    w: "10",
    h: "10",
    borderRadius: "full",
    bg: "background.white",
    boxShadow: "0px 2px 3px rgba(58, 21, 10, 0.1)",
    _focus: { boxShadow: "0px 2px 3px rgba(58, 21, 10, 0.1)" },
  }

  const prevId = `o-${id}-swiper-arrow--prev`
  const nextId = `o-${id}-swiper-arrow--next`

  return items?.length ? (
    <Box pos="relative" mb={{ base: "5", lg: "10" }} sx={{ ".swiper": { alignItems: "stretch" } }}>
      <Swiper
        lazy
        loop
        slidesPerView={2}
        spaceBetween={16}
        navigation={{ prevEl: `#${prevId}`, nextEl: `#${nextId}` }}
        breakpoints={{ 750: { slidesPerView: 3 }, 1024: { slidesPerView: 4 }, 1280: { slidesPerView: 6 } }}
      >
        {items.map((item, index) => (
          <SwiperSlide key={index}>
            <ProductCarouselItem item={item} />
          </SwiperSlide>
        ))}
      </Swiper>
      <Flex
        pos="absolute"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
        w="full"
        zIndex="overlay"
        justifyContent="space-between"
        pointerEvents="none"
      >
        <IconButton
          variant="unstyled"
          aria-label="Previous"
          icon={<Icon name="arrows/chevron-left" width="24" height="24" />}
          id={prevId}
          {...navButtonStyles}
          pointerEvents="initial"
        />
        <IconButton
          variant="unstyled"
          aria-label="Next"
          icon={<Icon name="arrows/chevron-right" width="24" height="24" />}
          id={nextId}
          {...navButtonStyles}
          pointerEvents="initial"
        />
      </Flex>
    </Box>
  ) : null
}
export default React.memo(FeaturedProductsCarousel)
